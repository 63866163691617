function Website() {
  return (
    <>
      <h2>Website</h2>
      <ul>
        <li>Protocol: SCP</li>
        <li>Host: omnomnm.com</li>
        <li>Port: 22</li>
        <li>Login with your username and password</li>
        <li>Folder: ~/www/[your-website]/</li>
      </ul>
    </>
  );
}

export default Website;
