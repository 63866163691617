function Password() {
  return (
    <>
      <h2>Change Password</h2>
      <p> If you forgot your password reach out to the server admin.</p>
      <ol>
        <li>Use an SSH client (like <a href="https://www.chiark.greenend.org.uk/~sgtatham/putty/latest.html">PuTTY</a>) and log in to omnomnm.com.</li>
        <li>Type passwd and press enter.</li>
        <li>Follow the prompts to change your password.</li>
        <li>Log out.</li>
      </ol>
    </>
  );
}

export default Password;
