import 'bootstrap/dist/css/bootstrap.min.css';

import logo from './assets/tumbeast.png';
import Buttons from './components/Buttons';
import Email from './components/Email';
import Website from './components/Website';
import Password from './components/Password';
import Footer from './components/Footer';

function App() {
  return (
    <div className='container'>
      <div className='jumbotron mt-4 pt-4' style={{background:'#fff'}}>
        <div className='row'>
          <div className='col-sm-12 col-md-7'>
            <h1 className='display-4'>Bob Omnomnm</h1>
            <p className='lead'>Hi! I'm Bob and I'm happy to serve you.</p>
          </div>
          <div className='col-sm-12 col-md-5'>
            <img
              className='img-fluid float-right'
              src={logo}
              width='250'
              alt=''
            />
          </div>
        </div>

        <hr className='my-4' />

        <div className='row'>
          <div className='col-md-12 col-lg-4'>
            <Buttons />
          </div>
          <div className='col-md-12 col-lg-4'>
            <Email />
          </div>
          <div className='col-md-12 col-lg-4'>
            <Website />
            <Password />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default App;
