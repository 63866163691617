
function Buttons() {
  return (
    <>
      <a
        className='btn btn-secondary btn-lg btn-block'
        href='https://webmail.omnomnm.com/'
        role='button'
      >
        Webmail
      </a>

      <a
        className='btn btn-secondary btn-lg btn-block'
        href='https://omnomnm.com/'
        role='button'
      >
        OMNOMNN
      </a>
    </>
  );
};

export default Buttons;
