{
  "name": "bob.omnomnm.com",
  "homepage": "https://bob.omnomnm.com",
  "version": "23.8.2",
  "source": "src/index.js",
  "private": true,
  "scripts": {
    "start": "parcel --open",
    "build": "parcel build"
  },
  "browserslist": "> 0.5%, last 2 versions, not dead",
  "dependencies": {
    "bootstrap": "^4.6.2",
    "react": "^18.2.0",
    "react-dom": "^18.2.0"
  },
  "devDependencies": {
    "parcel": "^2.9.3",
    "parcel-reporter-static-files-copy": "^1.5.2"
  }
}
